.intro{
    padding-top: 28%;
    padding-bottom: 12%;
    margin-bottom: -9%;
    @media only screen and (max-width: $break-large) {
        margin-bottom: -11%;
    }
    @media only screen and (max-width: $break-medium) {
        padding-top: 30%;
        padding-bottom: 15%;
        margin-bottom: -19.5%;
    }
    @media only screen and (max-width: $break-small) {
        padding-top: 150px;
        padding-bottom: 115px;
        margin-bottom: -108px;
    }
    .titles-wrap{
        margin-top: -14%;
        @media only screen and (max-width: $break-small) {
            padding-left: 0;
        }
    }
    .intro-text-wrap{
        margin-bottom: 7%;
        @media only screen and (max-width: $break-small) {
            padding-left: 0;
            margin-bottom: 0;
        }
    }
}

#about{
    padding-top: 21%;
    padding-bottom: 12%;
    margin-bottom: -9.1%;
    @media only screen and (max-width: $break-large) {
        margin-bottom: -11.7%;
    }
    @media only screen and (max-width: $break-medium) {
        padding-top: 23%;
        padding-bottom: 15%;
        margin-bottom: -21%;
    }
    @media only screen and (max-width: $break-small) {
        padding-top: 115px;
        padding-bottom: 115px;
        margin-bottom: -120px;

    }
    .about-text-wrap{
        padding-right: 15px;
        margin-bottom: 7%;
        @media only screen and (max-width: $break-small) {
            padding: 0;
            margin-bottom: 0;
        }
    }
    .about-titles-wrap{
        margin-top: -5%;
        @media only screen and (max-width: $break-small) {
            margin-top: 0;
            padding-left: 0;
        }
    }
}

#difference{
    padding-top: 20%;
    padding-bottom: 12%;
    margin-bottom: -9.4%;
    @media only screen and (max-width: $break-large) {
        margin-bottom: -12.4%;
    }
    @media only screen and (max-width: $break-medium) {
        padding-top: 30%;
        padding-bottom: 20%;
        margin-bottom: -25%;
    }
    @media only screen and (max-width: $break-small) {
        padding-top: 125px;
        padding-bottom: 150px;
        margin-bottom: -140px;
    }
    .dif-text-wrap{
        padding-right: 15px;
        @media only screen and (max-width: $break-small) {
            padding: 0;
            margin-bottom: 0;
        }
    }
    .dif-titles-wrap{
        margin-top: -10%;
        @media only screen and (max-width: $break-small) {
            padding-left: 0;
            margin-top: 0;
        }
    }
}

#values{
    padding-top: 20%;
    padding-bottom: 15%;
    margin-bottom: -5%;
    @media only screen and (max-width: $break-large) {
        padding-bottom: 20%;
        margin-bottom: -6.9%;
    }
    @media only screen and (max-width: $break-medium) {
        padding-top: 45%;
        padding-bottom: 30%;
        margin-bottom: -14%;
    }
    @media only screen and (max-width: $break-small) {
        padding-top: 144px;
        padding-bottom: 115px;
        margin-bottom: -75px;
    }
    p{
        padding-right: 30px;
        @media only screen and (max-width: $break-small) {
            padding: 0;
        }
    }
    h1{
        margin-top: -8%;
        margin-bottom: 15%;
        @media only screen and (max-width: $break-small) {
            margin-top: 0;
            margin-bottom: 50px;
        }
    }
    .values-wrap{
        margin-bottom: -9%;
        @media only screen and (max-width: $break-small) {
            margin-bottom: 0;
        }
        .value{
            @media only screen and (max-width: $break-small) {
                padding: 0;
                margin-bottom: 0;
            }
        }
    }
}

#people{
    padding-top: 15%;
    padding-bottom: 17%;
    margin-bottom: -8.7%;
    @media only screen and (max-width: $break-large) {
        margin-bottom: -11.1%;
    }
    @media only screen and (max-width: $break-medium) {
        padding-top: 20%;
        padding-bottom: 20%;
        margin-bottom: -24%;
    }
    @media only screen and (max-width: $break-small) {
        padding-top: 100px;
        padding-bottom: 125px;
        margin-bottom: -145px;
    }
    h2{
        padding-right: 30%;
        @media only screen and (max-width: $break-medium) {
            padding-right: 0;
        }
    }
    .people-titles-wrap{
        margin-top: -6%;
        padding-left: 0;
        @media only screen and (max-width: $break-small) {
            margin-top: 0;
        }
    }
    .people-text-wrap{
        padding-right: 25px;
        @media only screen and (max-width: $break-small) {
            padding: 0;
        }
    }
}

#services{
    padding-top: 21%;
    padding-bottom: 12%;
    margin-bottom: -8%;
    position: relative;
    @media only screen and (max-width: $break-large) {
        margin-bottom: -9%;
    }
    @media only screen and (max-width: $break-medium) {
        padding-top: 25%;
        padding-bottom: 25%;
        margin-bottom: -18%;
    }
    @media only screen and (max-width: $break-small) {
        padding-top: 145px;
        padding-bottom: 140px;
        margin-bottom: -100px;
    }
    ul{
        li{
            margin-bottom: 20px;
        }
    }
    .service{
        padding: 0;
        h2{
            @media only screen and (max-width: $break-small) {
                margin-top: 0;
            }
        }
    }
}

#contacts{
    padding-top: 20%;
    padding-bottom: 18%;
    margin-bottom: -4%;
    @media only screen and (max-width: $break-x-large) {
        padding-bottom: 22%;
        margin-bottom: -5.5%;
    }
    @media only screen and (max-width: $break-large) {
        padding-bottom: 22%;
        margin-bottom: -12%;
    }
    @media only screen and (max-width: $break-medium) {
        padding-top: 25%;
        padding-bottom: 200px;
        margin-bottom: -135px;
    }
    @media only screen and (max-width: $break-small) {
        padding-top: 100px;
        padding-bottom: 130px;
        margin-bottom: -110px;
        text-align: center;
    }
    .contacts-wrap{
        background: rgba(206,31,38,0.4);
        padding: 45px 0;
    }
    h1{
        margin-top: -7%;
        margin-bottom: 6%;
        @media only screen and (max-width: $break-medium) {
            margin-bottom: 50px;
        }
    }
    h2{
        font-size: 22px;
        margin-bottom: 10px;
        margin-top: 0;
    }
    p{
        margin-top: 0;
        margin-bottom: 5px;
        font-size: 16px;
    }
}
