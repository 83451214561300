//Colors
$white:     #ffffff;
$black:     #000000;
$red:       #ce1f26;
$grey:      #8a8a8a;


// Google Fonts
@import url('https://fonts.googleapis.com/css?family=Krona+One|Open+Sans:300,400,500,600,700');

$font-krona: 'Krona One', sans-serif;
$font-open-sans: 'Open Sans', sans-serif;

//Breakpoints for UIkit
$break-small: 639px;
$break-medium: 959px;
$break-large: 1199px;
$break-x-large: 1599px;
