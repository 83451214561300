html {
	box-sizing: border-box;
}

*,
*:before,
*:after { /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
	box-sizing: inherit;
}

img {
	height: auto; /* Make sure images are scaled correctly. */
	max-width: 100%; /* Adhere to container width. */
}

figure {
	margin: 1em 0; /* Extra wide images within figure tags don't overflow the content area. */
}

ul{
    //@extend .uk-list;
}

ul{
    li{
        margin-top: 0 !important;
    }
}

table{
    @extend .uk-table;
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
	max-width: 100%;
}


//Custom site wide styles
body{
  font-family: $font-open-sans;
}

h1{
  font-size: 40px;
  font-family: $font-krona;
  color: $white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  line-height: normal;
  letter-spacing: normal;
  @media only screen and (max-width: $break-small) {
      font-size: 25px;
  }
}

h2{
  font-weight: 600;
  font-size: 25px;
  color: $white;
  font-family: $font-open-sans;
  margin-top: 20px;
  text-shadow: 4px 4px 7px rgba(0,0,0,.75);
  @media only screen and (max-width: $break-small) {
      font-size: 18px;
  }
}
h3{
    font-weight: 600;
    font-size: 30px;
    color: $white;
    font-family: $font-open-sans;
    text-shadow: 4px 4px 7px rgba(0,0,0,.75);
    @media only screen and (max-width: $break-small) {
        font-size: 20px;
    }
}

p{
    font-weight: 300;
    line-height: normal;
    letter-spacing: normal;
    text-shadow: 4px 4px 7px rgba(0, 0, 0, 0.75);
}
a{
    font-family: $font-open-sans;
}


.intro, .about, .difference, .values, .people, .services, .contacts{
    p{
        color: $white;
        font-size: 20px;
        @media only screen and (max-width: $break-small) {
            font-size: 16px;
        }
    }
    ul{
        li{
            color: $white;
            font-size: 20px;
            font-weight: 300;
            line-height: normal;
            letter-spacing: normal;
            text-shadow: 4px 4px 7px rgba(0, 0, 0, 0.75);
            @media only screen and (max-width: $break-small) {
                font-size: 16px;
            }
        }
    }
}
