footer{
    color: $white;
    .footer-copyright{
        font-size: 14px;
        @media only screen and (max-width: $break-large) {
            display: block;
            text-align: center;
            margin-top: 20px;
        }
    }
    .uk-navbar{
        @media only screen and (max-width: $break-large) {
            display: block;
        }
        @media only screen and (max-width: $break-medium) {
            text-align: center;
        }
    }
    .uk-navbar-right{
        @media only screen and (max-width: $break-large) {
            display: block;
        }
    }
    #footer-menu{
        @media only screen and (max-width: $break-medium) {
            display: block;
        }
        li{
            @media only screen and (max-width: $break-medium) {
                display: inline-block;
            }
            a{
                height: auto;
                color: $white;
                text-transform: capitalize;
                font-family: $font-open-sans;
            }
        }
    }

    .siteby{
        @media only screen and (max-width: $break-large) {
            text-align: center;
        }
    }

}
