header{
    padding-top: 25px;
    margin-bottom: -1.5%;
    @media only screen and (max-width: $break-medium) {
        margin-bottom: -3%;
    }
    @media only screen and (max-width: $break-small) {
        padding-top: 0;
        margin-bottom: 0;
    }
    .resp-top-nav{
        background-color: $black;
        padding: 0 15px;
        .uk-navbar-toggle{
            display: block;
            float: left;
            height: auto;
            background-color: $red;
            padding: 10px;
            position: relative;
            z-index: 1000;
            color: $white;
        }
        .language{
            padding: 10px 0;
            display: block;
            position: relative;
            z-index: 999;
        }
    }

    .language{
        color: $grey;
        font-size: 14px;
        font-weight: bold;
        margin-right: 20px;
        @media only screen and (max-width: $break-medium) {
            font-size: 20px;
            position: relative;
            z-index: 1000;
        }
        @media only screen and (max-width: $break-small) {
            color: $white;
        }
        a{
            color: $grey;
            font-size: 14px;
            font-weight: bold;
            &:hover{
                text-decoration: none;
            }
            @media only screen and (max-width: $break-medium) {
                font-size: 20px;
            }
            @media only screen and (max-width: $break-small) {
                color: $white;
            }
        }
        .active{
            color: $red;
        }
    }

    .uk-navbar-container{
        padding-top: 10px;
        @media only screen and (max-width: $break-large) {
            display: block;
            text-align: center;
        }
    }
    .uk-navbar-right{
        @media only screen and (max-width: $break-large) {
            display: block;
        }
    }
    .header-logo{
        margin-right: 50px;
        @media only screen and (max-width: $break-large) {
            margin-top: -40px;
        }
        @media only screen and (max-width: $break-small) {
            margin-top: 0px;
            margin-right: 0;
        }
        img{
            max-height: 75px;
            @media only screen and (max-width: $break-small) {
                max-height: 55px;
                margin-top: 20px;
            }
        }
    }
    #main-menu{
        @media only screen and (max-width: $break-large) {
            display: block;
        }
        @media only screen and (max-width: $break-small) {
            padding-top: 20px;
        }
        li{
            @media only screen and (max-width: $break-large) {
                display: inline-block;
            }
            a{
                color: $black;
                font-size: 16px;
                font-weight: 600;
                text-transform: capitalize;
                padding: 0 20px;
                font-family: $font-open-sans;
                @media only screen and (max-width: $break-small) {
                    height: auto;
                    padding: 10px 20px;
                }
            }
        }
    }
    .french-menu{
        li{
            text-align: center;
        }
    }
}


#main-menu-resp{
    display: block;
    li{
        a{
            color: $white;
            height: auto;
            padding: 25px 15px;
        }
    }
}
.uk-offcanvas-bar{
    width: 230px;
    padding-top: 50px;
    background-color: $black;
    .uk-close{
        color: $white;
        svg{
            line{
                stroke-width: 1.5;
            }
        }
    }
}
