// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

/* Empty classes for styling WP */
/* Taken from here http://www.wpbeginner.com/wp-themes/default-wordpress-generated-css-cheat-sheet-for-beginners/ */
/* Uncomment needed class and use */

/* ------ Default WISIWYG Editor Styles -----*/
.alignnone {
    margin: 5px 20px 20px 0;
    max-width: 100%;
    height: auto;
}
.aligncenter, div.aligncenter {
    @extend .uk-align-center;
}

.alignright {
    @extend .uk-align-right;
}

.alignleft {
    @extend .uk-align-left;
}

.wp-caption {
    background: #fff;
    border: 1px solid #f0f0f0;
    max-width: 96%; /* Image does not overflow the content area */
    padding: 5px 3px 10px;
    text-align: center;
}

.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
}

.wp-caption p.wp-caption-text {
    font-size: 11px;
    line-height: 17px;
    margin: 0;
    padding: 0 4px 5px;
}

//.wp-smiley {}

//blockquote.left {}
//blockquote.right {}

//.gallery dl {}
//.gallery dt {}
//.gallery dd {}
//.gallery dl a {}
//.gallery dl img {}
//.gallery-caption {}

//.size-full {}
//.size-large {}
//.size-medium {}
//.size-thumbnail {}



/* ------ Default Body Class Styles ------ */
//.rtl {}
//.home {}
//.blog {}
//.archive {}
//.date {}
//.search {}
//.paged {}
//.attachment {}
//.error404 {}
//.single postid-(id) {}
//.attachmentid-(id) {}
//.attachment-(mime-type) {}
//.author {}
//.author-(user_nicename) {}
//.category {}
//.category-(slug) {}
//.tag {}
//.tag-(slug) {}
//.page-parent {}
//.page-child parent-pageid-(id) {}
//.page-template page-template-(template file name) {}
//.search-results {}
//.search-no-results {}
//.logged-in {}
//.paged-(page number) {}
//.single-paged-(page number) {}
//.page-paged-(page number) {}
//.category-paged-(page number) {}
//.tag-paged-(page number) {}
//.date-paged-(page number) {}
//.author-paged-(page number) {}
//.search-paged-(page number) {}


/* ------ Default Post Styles ------ */
//.post-id {}
//.post {}
//.page {}
//.attachment {}
//.sticky {}
//.hentry {}
//.category-misc {}
//.category-example {}
//.tag-news {}
//.tag-wordpress {}
//.tag-markup {}

//.format-image {}
//.format-gallery {}
//.format-chat {}
//.format-link {}
//.format-quote {}
//.format-status {}
//.format-video {}


/* ------ Default WordPress Widget Styles ------ */
//.widget {}

//#searchform {}
//.widget_search {}
//.screen-reader-text {}

//.widget_meta {}
//.widget_meta ul {}
//.widget_meta ul li {}
//.widget_meta ul li a {}

//.widget_links {}
//.widget_links ul {}
//.widget_links ul li {}
//.widget_links ul li a {}

//.widget_archive {}
//.widget_archive ul {}
//.widget_archive ul li {}
//.widget_archive ul li a {}
//.widget_archive select {}
//.widget_archive option {}

//.widget_pages {}
//.widget_pages ul {}
//.widget_pages ul li {}
//.widget_pages ul li a {}

//.widget_links {}
//.widget_links li:after {}
//.widget_links li:before {}
//.widget_tag_cloud {}
//.widget_tag_cloud a {}
//.widget_tag_cloud a:after {}
//.widget_tag_cloud a:before {}

//.widget_calendar {}
//#calendar_wrap {}
//#calendar_wrap th {}
//#calendar_wrap td {}
//#wp-calendar tr td {}
//#wp-calendar caption {}
//#wp-calendar a {}
//#wp-calendar #today {}
//#wp-calendar #prev {}
//#wp-calendar #next {}
//#wp-calendar #next a {}
//#wp-calendar #prev a {}

//.widget_categories {}
//.widget_categories ul {}
//.widget_categories ul li {}
//.widget_categories ul ul.children {}
//.widget_categories a {}
//.widget_categories select{}
//.widget_categories select#cat {}
//.widget_categories select.postform {}
//.widget_categories option {}
//.widget_categories .level-0 {}
//.widget_categories .level-1 {}
//.widget_categories .level-2 {}
//.widget_categories .level-3 {}

//.recentcomments {}
//#recentcomments {}
//#recentcomments li {}
//#recentcomments li a {}
//.widget_recent_comments {}

//.widget_recent_entries {}
//.widget_recent_entries ul {}
//.widget_recent_entries ul li {}
//.widget_recent_entries ul li a {}

//.textwidget {}
//.widget_text {}
//.textwidget p {}



/* Text meant only for screen readers. */
.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
	background-color: #eee;
	clip: auto !important;
	clip-path: none;
	color: #444;
	display: block;
	font-size: 1em;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000;
	/* Above WP toolbar. */
}

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
	outline: 0;
}
